<template>
    <div class="m-3 m-lg-4">
        <h2 class="font-bayon mb-4">Wer war dabei?</h2>
        <h2 class="text-center" v-if="!this.$store.getters.isArchiveInitialized">
            <CSpinner />
        </h2>
        <span v-else-if="archive.length === 0">
            <p>
                Aktuell liegen uns <strong>keine</strong> Bilder vor, die wir nicht zuordnen können.
                <CIcon name="cilHappy" />
            </p>
            <p class="mt-3">
                Schauen Sie später gern nochmal rein, die Seite wird fortlaufend aktualisiert.
            </p>
            <p class="mt-3">
                <strong>Vielen Dank für Ihre Mithilfe!</strong>
            </p>
        </span>
        <span v-else>
            <p>
                Für die folgenden Bilder liegen uns aktuell keine oder zu wenig Informationen vor,
                um sie sinnvoll in die Chronik einordnen zu können.
            </p>
            <p class="mt-3">
                Haben Sie Informationen, die uns helfen könnten? Teilen Sie uns gern alles mit, was Sie wissen!
                Klicken Sie dazu einfach auf den <strong><CIcon name="cil-bullhorn" /></strong>-Button
                unter dem entsprechenden Bild.
            </p>
            <p class="mt-3">
                <strong>Vielen Dank!</strong>
            </p>
            <hr class="border-light" />
            <span v-for="(entry, index) in archive" :key="index">
                <CRow>
                    <CCol sm="12" lg="6">
                        <p><i>{{ entry.text }}</i></p>
                        <img class="my-2" :id="'img-archive-' + index" :src="entry.image" width="95%" @click="onImgClick('img-archive-' + index)" />
                        <img v-if="entry.add_image_1 !== undefined && entry.add_image_1 !== ''" class="my-2" :id="'add-img-1-archive-' + index" :src="entry.add_image_1" width="95%" @click="onImgClick('add-img-1-archive-' + index)" />
                        <img v-if="entry.add_image_2 !== undefined && entry.add_image_2 !== ''" class="my-2" :id="'add-img-2-archive-' + index" :src="entry.add_image_2" width="95%" @click="onImgClick('add-img-2-archive-' + index)" />
                        <img v-if="entry.add_image_3 !== undefined && entry.add_image_3 !== ''" class="my-2" :id="'add-img-3-archive-' + index" :src="entry.add_image_3" width="95%" @click="onImgClick('add-img-3-archive-' + index)" />
                        <img v-if="entry.add_image_4 !== undefined && entry.add_image_4 !== ''" class="my-2" :id="'add-img-4-archive-' + index" :src="entry.add_image_4" width="95%" @click="onImgClick('add-img-4-archive-' + index)" />
                    </CCol>
                </CRow>
                <CButton
                    class="mt-2 border-white text-white"
                    color="white"
                    shape="square"
                    size="sm"
                    title="Informationen mitteilen"
                    @click="$router.push({
                        name: 'reportArchive',
                        params: { 
                            selectedEntryID: index,
                            returnFilterType: $store.getters.getFilterType,
                            returnFilterValue: $store.getters.getFilterValue
                        }
                    });">
                    <CIcon name="cil-bullhorn" />
                </CButton>
                <hr class="border-light" v-if="index < (archive.length - 1)" />
            </span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'VfLArchive',
    computed: {
        archive() {
            return this.$store.getters.getArchive;
        }
    },
    mounted() {
        this.$store.dispatch('updateFilter', [-1, 0]);
    },
    methods: {
        onTransitionEnd(event) {
            event.target.style.overflowY = 'hidden';
            setTimeout(function () { event.target.style.overflowY = 'auto'; });
        },
        onImgClick(id) {
            var el = document.getElementById(id);
            if (el.clickLocked)
                return;

            if (el.clickCount === undefined)
                el.clickCount = 0;
            
            if (el.clickCount === 0)
                setTimeout(() => { el.clickCount = 0; }, 500);
            el.clickCount++;

            el.clickLocked = true;
            setTimeout(() => { el.clickLocked = false; }, 50);

            if (el.zoomToggled) {
                if (el.clickCount < 2)
                    el.clickCount = 0;
                el.style.transform = '';
                setTimeout(() => { el.style.zIndex = 0; }, 400);
                el.zoomToggled = false;
            }

            if (el.clickCount === 2) {
                // double click
                if (this.$isMobile()) {
                    el.style.position = 'relative';
                    el.style.zIndex = 1000;
                    el.style.touchAction = 'manipulation';
                    el.style.transform = 'scale(' + (window.innerHeight - 100) / el.height + ')';
                    el.style.transition = 'transform ease-in-out 0.4s';
                    el.style.transformOrigin = '10px';
                    el.zoomToggled = true;
                    el.addEventListener('webkitTransitionEnd', this.onTransitionEnd);
                }
                else {
                    let win = window.open(el.src, '_blank');
                    win.document.title = el.title;
                    win.focus();
                }
            }
            else if (el.clickCount >= 3) {
                // triple click
                let win = window.open(el.src, '_blank');
                win.document.title = el.title;
                win.focus();
            }
            
            return false;
        }
    }
}
</script>