import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import StoryblokClient from 'storyblok-js-client';

Vue.use(Vuex);

let token;
if (process.env.NODE_ENV === 'development')
    token = 'XupPMgWueQdM7AAVfYKqpQtt';
else
    token = 'yq1oxAyrVMzAthfCRbGVxgtt';

let storyapi = new StoryblokClient({
    accessToken: token
});

const getDefaultState = () => {
    return {
        data: {
            fetchIndex: 0,
            categories: [],
            entries: [],
            persons: [],
            keywords: [],
            archive: [],
            archiveInitialized: false,
            trackRequests: []
        },
        view: {
            pictureIndexShow: false,
            sidebarShow: 'responsive',
            sidebarMinimize: false,
            timelineWidth: 300,
            filterType: 0,
            filterValue: 0,
            filterChangeCount: 0,
            entries: [],
            mostRecentEntries: [],
            nonChronicleKeywords: [],
            standaloneKeywords: []
        }
    }
}

const getters = {
    isFullyInitialized: (state) => {
        return state.data.fetchIndex >= 4;
    },
    getFetchIndex: (state) => {
        return state.data.fetchIndex;
    },
    getCategories: (state) => {
        return state.data.categories;
    },
    getPersons: (state) => {
        return state.data.persons;
    },
    getPersonIndex: (state) => (person) => {
        return state.data.persons.indexOf(person);
    },
    getPerson: (state) => (index) => {
        if (index < state.data.persons.length)
            return state.data.persons[index];
        return '';
    },
    getKeywords: (state) => {
        return state.data.keywords;
    },
    getKeywordIndex: (state) => (keyword) => {
        return state.data.keywords.indexOf(keyword);
    },
    getKeyword: (state) => (index) => {
        if (index < state.data.keywords.length)
            return state.data.keywords[index];
        return '';
    },
    getViewPictureIndexShow: (state) => {
        return state.view.pictureIndexShow;
    },
    getViewSidebarShow: (state) => {
        return state.view.sidebarShow;
    },
    getViewSidebarMinimize: (state) => {
        return state.view.sidebarMinimize;
    },
    getTimelineWidth: (state) => {
        return state.view.timelineWidth;
    },
    getFilterType: (state) => {
        return state.view.filterType;
    },
    getFilterValue: (state) => {
        return state.view.filterValue;
    },
    getFilterChangeCount: (state) => {
        return state.view.filterChangeCount;
    },
    getDataEntries: (state) => {
        return state.data.entries;
    },
    getDataEntry: (state) => (id) => {
        if (id === -1)
            return null;
        for (var i = 0; i < state.data.entries.length; ++i) {
            if (state.data.entries[i].__ob__.dep.id === id)
                return state.data.entries[i];
        }
        return null;
    },
    getViewEntries: (state) => {
        return state.view.entries;
    },
    getMostRecentEntries: (state) => {
        return state.view.mostRecentEntries;
    },
    getNonChronicleKeywords: (state) => {
        return state.view.nonChronicleKeywords;
    },
    isNonChronicleKeyword: (state) => (keyword) => {
        return state.view.nonChronicleKeywords.indexOf(keyword) != -1;
    },
    getStandaloneKeywords: (state) => {
        return state.view.standaloneKeywords;
    },
    isStandaloneKeyword: (state) => (keyword) => {
        return state.view.standaloneKeywords.indexOf(keyword) != -1;
    },
    getArchive: (state) => {
        return state.data.archive;
    },
    getArchiveEntry: (state) => (index) => {
        if (index < state.data.archive.length)
            return state.data.archive[index];
        return null;
    },
    isArchiveInitialized: (state) => {
        return state.data.archiveInitialized;
    },
    getDataTrackRequests: (state) => {
        return state.data.trackRequests;
    }
}

const mutations = {
    setFetchIndex (state, [fetchIndex]) {
        state.data.fetchIndex = fetchIndex;
    },
    addCategory (state, [category]) {
        for (var i = 0; i < state.data.categories.length; ++i) {
            if (state.data.categories[i].title === category.title)
                return;
        }
        state.data.categories.push(category);
    },
    toggleSidebarMobile (state) {
        const sidebarClosed = [false, 'responsive'].includes(state.view.sidebarShow);
        state.view.sidebarShow = sidebarClosed ? true : 'responsive';
    },
    setSidebarShow (state, [value]) {
        state.view.sidebarShow = value;
    },
    setSidebarMinimize (state, [value]) {
        state.view.sidebarMinimize = value;
    },
    setTimelineWidth (state, [width]) {
        state.view.timelineWidth = width;
    },
    setFilter (state, [filterType, filterValue]) {
        state.view.filterType = filterType;
        state.view.filterValue = filterValue;
        state.view.filterChangeCount++;
    },
    addDataEntries (state, [entries]) {
        entries.forEach(entry => {
            entry.image = '';
            entry.images = [];
            entry.caption = '';
            state.data.entries.push(entry);
            if (entry.persons != undefined) {
                entry.persons.forEach(person => {
                    if (state.data.persons.indexOf(person) === -1) {
                        state.data.persons.push(person);
                    }
                });
            }
            if (entry.keywords != undefined) {
                entry.keywords.forEach(keyword => {
                    if (state.data.keywords.indexOf(keyword) === -1)
                        state.data.keywords.push(keyword);
                });
            }
        });
    },
    clearViewEntries (state) {
        state.view.entries = [];
    },
    addViewEntry (state, [entry]) {
        state.view.entries.push(entry);
    },
    clearMostRecentEntries (state) {
        state.view.mostRecentEntries = [];
    },
    addMostRecentEntry (state, [entry]) {
        state.view.mostRecentEntries.push(entry);
    },
    addArchiveEntry (state, [entry]) {
        state.data.archive.push(entry);
    },
    setArchiveInitialized (state, [initialized]) {
        state.data.archiveInitialized = initialized;
    },
    setDataTrackRequests (state, [trackRequests]) {
        state.data.trackRequests = trackRequests;
    }
}

const helper = {
    setupViewEntry(entry, state, getters) {
        entry.dateFrom = new Date(entry.date.substr(0, 10) + 'T00:00:00');
        entry.dateTo = new Date();
        if (entry.date_to != undefined) {
            entry.dateTo = new Date(entry.date_to.substr(0, 10) + 'T00:00:00');
        }

        entry.image = '';
        entry.images = [];

        entry.introShort = '';
        entry.introLong = '';
        entry.htmlDesc = '';
        entry.htmlAdditional = '';
        entry.numAdditional = 0;
        entry.htmlTags = '';
        if (entry.content != undefined) {
            entry.htmlDesc = storyapi.richTextResolver.render(entry.content);

            var imgs = [];
            var img = '';
            var id = '';
            var pos0 = entry.htmlDesc.indexOf('<p><img ');
            var pos1 = 0;

            if (pos0 > 0) {
                entry.introLong = entry.htmlDesc.substring(0, pos0);
                entry.htmlDesc = entry.htmlDesc.substring(pos0);

                pos0 = entry.introLong.indexOf('<p>');
                if (pos0 != -1) {
                    pos1 = entry.introLong.indexOf('</p>');
                    entry.introLong = entry.introLong.substring(pos0 + 3, pos1);
                }

                if (entry.introLong.length >= 260)
                    entry.introShort = entry.introLong.substring(0, 260) + '...';               
                pos0 = 0;
            }

            var paths = [];
            var pathPos0, pathPos1;

            while (pos0 != -1) {
                if (entry.image_width === undefined ||
                    entry.image_width === '')
                    entry.image_width = 700;

                if (entry.image_width > state.view.timelineWidth)
                    entry.image_width = state.view.timelineWidth;

                if (entry.image_width_alt === undefined ||
                    entry.image_width_alt > state.view.timelineWidth)
                    entry.image_width_alt = state.view.timelineWidth;

                id = 'img-' + entry.__ob__.dep.id + '-' + imgs.length;
                img = '<p><img id="' + id + '" ' +
                    'onclick="document.getElementById(\'timeline\').__vue__.$parent.onImgClick(\'' + id + '\');" ' +
                    'width="';
                if (entry.images_alt === undefined ||
                    entry.images_alt.indexOf(imgs.length.toString()) === -1) {
                    img += entry.image_width;
                }
                else {
                    img += entry.image_width_alt;
                }
                img += 'px" ';

                pos1 = entry.htmlDesc.indexOf('<p><img ', pos0 + 8);
                if (pos1 === -1) {
                    img += entry.htmlDesc.substr(pos0 + 8);
                    entry.htmlDesc = entry.htmlDesc.substr(0, pos0);
                    pos0 = -1;
                }
                else {
                    img += entry.htmlDesc.substr(pos0 + 8, pos1 - (pos0 + 8));
                    entry.htmlDesc = entry.htmlDesc.substr(0, pos0) + entry.htmlDesc.substr(pos1);
                    pos0 = entry.htmlDesc.indexOf('<p><img ');
                }

                img = img.replace('alt=""', 'alt="' + entry.headline + '"');
                img = img.replace('title=""', 'title="' + entry.headline + '"');
                imgs.push(img);

                pathPos0 = img.indexOf('src="');
                pathPos1 = img.indexOf('"', pathPos0 + 5);
                paths.push(img.substring(pathPos0 + 5, pathPos1));
            }

            if (imgs.length > 0) {
                if (imgs.length === 1) {
                    pos0 = imgs[0].indexOf('<img');
                    pos1 = imgs[0].indexOf('/>', pos0);
                    entry.image = imgs[0].substring(pos0, pos1 + 2);
                    entry.images.push(entry.image);
                    entry.htmlDesc += imgs[0];
                }
                else {
                    var imgIndex = Math.floor(Math.random() * 1000) % imgs.length;
                    pos0 = imgs[imgIndex].indexOf('<img');
                    pos1 = imgs[imgIndex].indexOf('/>', pos0);
                    entry.image = imgs[imgIndex].substring(pos0, pos1 + 2);
                    entry.images.push(entry.image);
                    entry.htmlDesc += imgs[imgIndex];
                    imgs.splice(imgIndex, 1);
                    paths.splice(imgIndex, 1);

                    for (imgIndex = 0; imgIndex < imgs.length; ++imgIndex) {
                        ++entry.numAdditional;
                        pos0 = imgs[imgIndex].indexOf('<img');
                        pos1 = imgs[imgIndex].indexOf('/>', pos0);
                        entry.images.push(imgs[imgIndex].substring(pos0, pos1 + 2));
                        entry.htmlAdditional += imgs[imgIndex];
                    }
                }
            }

        }

        if (entry.persons != undefined && entry.persons.length > 0) {
            entry.htmlTags += '<p class="mb-0"><b>Personen:</b>&nbsp;';
            entry.persons.forEach((person, index) => {
                if (index > 0)
                    entry.htmlTags += ', ';
                entry.htmlTags += '<a href="#" onclick="document.getElementById(\'timeline\').__vue__.$parent.setFilter(1, ' + getters.getPersonIndex(person) + '); return false;">' + person + '</a>'
            });
            entry.htmlTags += '</p>';
        }

        if (entry.keywords != undefined && entry.keywords.length > 0) {
            entry.htmlTags += '<p class="mb-0"><b>Schlagworte:</b>&nbsp;';
            entry.keywords.forEach((keyword, index) => {
                if (index > 0)
                    entry.htmlTags += ', ';
                entry.htmlTags += '<a href="#" onclick="document.getElementById(\'timeline\').__vue__.$parent.setFilter(2, ' + getters.getKeywordIndex(keyword) + '); return false;">' + keyword + '</a>';
            });
            entry.htmlTags += '</p>';
        }

        entry.caption = '';
        if (getters.getViewPictureIndexShow) {
            entry.caption += '#' + entry.__ob__.dep.id + ' - ';
        }

        switch (parseInt(entry.date_type)) {
        case 0:
            if (entry.date_to !== undefined && entry.date_to !== '') {
                entry.caption += ('0' + entry.dateFrom.getDate()).slice(-2)  + '.';
                entry.caption += ('0' + (entry.dateFrom.getMonth() + 1)).slice(-2)  + '.';
                entry.caption += entry.dateFrom.getFullYear() + ' - ';
                entry.caption += ('0' + entry.dateTo.getDate()).slice(-2)  + '.';
                entry.caption += ('0' + (entry.dateTo.getMonth() + 1)).slice(-2)  + '.';
                entry.caption += entry.dateTo.getFullYear();
            }
            else {
                entry.caption += entry.dateFrom.getDate()  + '. ';
                entry.caption += entry.dateFrom.toLocaleDateString('de-DE', { month: 'long' }) + ' ';
                entry.caption += '\'' + ('0' + (entry.dateFrom.getYear() % 100)).slice(-2);
            }
            break;
        case 1:
            entry.caption += entry.dateFrom.toLocaleDateString('de-DE', { month: 'long' }) + ' ';
            entry.caption += '\'' +  ('0' + (entry.dateFrom.getYear() % 100)).slice(-2);
            break;
        case 2: 
            entry.caption += entry.dateFrom.getFullYear();
            break;
        default:
            entry.caption += entry.dateFrom.getFullYear() + '/' + entry.dateTo.getFullYear();
            break;
        }
    
        return entry;
    }
}

const actions = {
    initStoryblok: ({ dispatch, getters }) => {
        if (getters.isFullyInitialized)
            return;

        window.storyblok.init({
            accessToken: token
        });

        window.storyblok.on('change', () => {
            dispatch('fetchCategories', ['draft']);
        });

        window.storyblok.pingEditor(() => {
            if (window.storyblok.isInEditor() ||
                process.env.NODE_ENV === 'development') {
                dispatch('fetchCategories', ['draft']);
            } else {
                dispatch('fetchCategories', ['published']);
            }
        });
    },
    fetchCategories: ({ commit, dispatch }, [version]) => {
        storyapi.get('cdn/stories/categories', {
            version: version
        })
        .then((response) => {
            response.data.story.content.data.forEach(item =>
                commit('addCategory', [{'title': item.title}])
            );
            dispatch('fetchData', [version]);
            dispatch('fetchArchive', [version]);
        })
        .catch((error) => {
            console.log(error);
        });
    },
    fetchData: ({ commit, dispatch, state }, [version]) => {
        storyapi.get('cdn/stories/data/' + state.data.fetchIndex, {
            'version': version
        })
        .then((response) => {
            if (response.data.story.content.data != undefined &&
                response.data.story.content.data.length > 0) {
                commit('addDataEntries', [response.data.story.content.data]);
                dispatch('applyFilter');
            }

            if (state.data.fetchIndex < 4) {
                commit('setFetchIndex', [state.data.fetchIndex + 1]);
                dispatch('fetchData', [version]);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },
    fetchArchive: ({ commit }, [version]) => {
        storyapi.get('cdn/stories/archive', {
            'version': version
        })
        .then((response) => {
            response.data.story.content.data.forEach(item => commit('addArchiveEntry', [item]));
            commit('setArchiveInitialized', [true]);
        });
    },
    fetchTrackRequests: ({ commit }, [live]) => {
        Axios.get('trackRequest.php', { params: { live: live } })
        .then((response) => {
            commit('setDataTrackRequests', [response.data]);
        });
    },
    applyFilter: ({ commit, state, getters }) => {
        let index = -1;
        commit('clearViewEntries');
        state.data.entries.forEach((entry) => {
            ++index;
            entry = helper.setupViewEntry(entry, state, getters);

            if (state.view.filterType === 0) {
                var minYear = 1972 + (state.view.filterValue * 10);
                var maxYear = minYear + 9;
                if (state.view.filterValue === 4)
                    maxYear += 100;
                if (entry.dateFrom.getFullYear() < minYear || entry.dateFrom.getFullYear() > maxYear)
                    return;

                if (entry.keywords != undefined) {
                    for (var i = 0; i < entry.keywords.length; ++i) {
                        if (state.view.nonChronicleKeywords.indexOf(entry.keywords[i]) != -1)
                            return;
                    }
                }
            }
            else if (state.view.filterType === 1) {
                if (entry.persons === undefined ||
                    entry.persons.indexOf(getters.getPerson(state.view.filterValue)) === -1)
                    return;
            }
            else if (state.view.filterType === 3) {
                if (index !== state.view.filterValue)
                    return;
            }
            else {
                if (entry.keywords === undefined ||
                    entry.keywords.indexOf(getters.getKeyword(state.view.filterValue)) === -1)
                    return;
            }

            commit('addViewEntry', [{
                id: entry.__ob__.dep.id,
                index: index,
                from: entry.dateFrom,
                caption: entry.caption,
                title: entry.headline,
                introShort: entry.introShort,
                introLong: entry.introLong,
                introLongShow: entry.introShort.length === 0,
                description: entry.htmlDesc,
                additional: entry.htmlAdditional,
                additionalNum: entry.numAdditional,
                additionalShow: false,
                videoHeadline: entry.video_headline,
                videoDescription: entry.video_description,
                videoURL: entry.video_url,
                tags: entry.htmlTags,
                tagsShow: false,
                hintCounter: 0
            }]);
        });
    },
    updateFilter: ({ commit, dispatch }, [index, filter]) => {
        commit('setFilter', [index, filter]);
        dispatch('applyFilter');
    },
    updateMostRecent: ({ state, getters, commit }, [numEntries]) => {
        commit('clearMostRecentEntries');

        var sorted = [];
        for (var i = 0; i < state.data.entries.length; ++i) {
            if (state.data.entries[i].date_created === undefined)
                sorted.push({ index: i, date: new Date(new Date('2022-03-31').getTime() + 36000000 + i) });
            else
                sorted.push({ index: i, date: new Date(state.data.entries[i].date_created.date) });
        }
        sorted.sort((a,b) => b.date - a.date);
        if (numEntries >= 0 && sorted.length > numEntries)
            sorted.length = numEntries;

        sorted.forEach((sortedEntry) => {
            const entry = helper.setupViewEntry(state.data.entries[sortedEntry.index], state, getters);
            commit('addMostRecentEntry', [{
                id: entry.__ob__.dep.id,
                index: sortedEntry.index,
                changed: sortedEntry.date,
                from: entry.dateFrom,
                caption: entry.caption,
                title: entry.headline,
                introShort: entry.introShort,
                introLong: entry.introLong,
                introLongShow: entry.introShort.length === 0,
                description: entry.htmlDesc,
                additional: entry.htmlAdditional,
                additionalNum: entry.numAdditional,
                additionalShow: false,
                tags: entry.htmlTags,
                tagsShow: false,
                hintCounter: 0
            }]);
        });
    },
    submitReport: ({ store }, [entry, imageURI, userName, userMail, userMessage]) => {
        store;
        var form = new FormData();
        form.append('type', 'report');
        form.append('entry_id', entry.__ob__.dep.id);
        form.append('entry_caption', entry.caption);
        form.append('entry_title', entry.headline);
        form.append('image_uri', imageURI);
        form.append('user_name', userName);
        form.append('user_mail', userMail);
        form.append('user_message', userMessage);
        Axios.post('mailer.php', form);
    },
    submitReportArchive: ({ store }, [entry, userName, userMail, userMessage]) => {
        store;
        var form = new FormData();
        form.append('type', 'reportArchive');
        form.append('entry_text', entry.text);
        form.append('image_uri', entry.image);
        form.append('user_name', userName);
        form.append('user_mail', userMail);
        form.append('user_message', userMessage);
        Axios.post('mailer.php', form);
    },
    submitRemoval: ({ store }, [entry, imageURI, userName, userMail, userMessage]) => {
        store;
        var form = new FormData();
        form.append('type', 'remove');
        form.append('entry_id', entry.__ob__.dep.id);
        form.append('entry_caption', entry.caption);
        form.append('entry_title', entry.headline);
        form.append('image_uri', imageURI);
        form.append('user_name', userName);
        form.append('user_mail', userMail);
        form.append('user_message', userMessage);
        Axios.post('mailer.php', form);
    },
    submitFeedback: ({ store }, [userName, userMail, userMessage]) => {
        store;
        var form = new FormData();
        form.append('type', 'feedback');
        form.append('user_name', userName);
        form.append('user_mail', userMail);
        form.append('user_message', userMessage);
        Axios.post('mailer.php', form);
    },
    submitTrackRequest: ({ store }, [artist, track]) => {
        store;
        var form = new FormData();
        form.append('artist', artist);
        form.append('track', track);
        Axios.post('trackRequest.php', form);
    }
}

export default new Vuex.Store({
    state: getDefaultState(),
    getters,
    mutations,
    actions
})