<template>
    <div class="vfl-header">
        <div class="d-lg-none">
            <CHeader class="pl-3 py-1 shadow">
                <CButton @click="$store.commit('toggleSidebarMobile')">
                    <CIcon size="xl" name="cilMenu" class="text-white"></CIcon>
                </CButton>
                <CHeaderBrand to="/">
                    <img class="ml-1 mr-3 my-2" src="@/assets/images/ball_only.png" height="40" />
                    <span class="text-white mt-2">
                        <h4 class="m-0 font-bayon">VfL Hameln Basketball</h4>
                        <h6 class="mt-0 mb-2 font-bayon">50 Jahre - Eine Chronik in Bildern</h6>
                    </span>
                </CHeaderBrand>
            </CHeader>
        </div>
        <div
            v-if="this.$store.getters.getFilterType >= 0 && this.$store.getters.getFilterType <= 2"
            class="d-flex m-3 mx-lg-5 mt-lg-4">
            <v-select
                id="selectFilterType" ref="selectFilterType"
                style="width: 130px; background-color: #0070bd;"
                :options="this.filterTypeOptions"
                :reduce="(option) => option.value"
                :value="this.$store.getters.getFilterType"
                :searchable="false"
                :clearable="false"
                :components="{OpenIndicator}"
                @input="onFilterTypeChange" />
            <v-select
                id="selectFilterValue" ref="selectFilterValue"
                class="flex-grow-1"
                :key="this.$store.getters.getFilterChangeCount"
                :options="this.filterValueOptions"
                :reduce="(option) => option.value"
                :value="(this.filterValueOptions.length > 0) ? this.$store.getters.getFilterValue : null"
                :clearable="false"
                @input="onFilterValueChange" />
        </div>
        <div class="d-none d-lg-block mx-5 mt-3 mb-2 font-bayon"
             v-if="this.$store.getters.getFilterType === 0 && this.$store.getters.getCategories.length > 0">
            <h1 >{{this.$store.getters.getCategories[this.$store.getters.getFilterValue].title}}</h1>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VfLHeader',
        data() {
            return {
                selectedCategory: 0,
                selectedPerson: 0,
                selectedKeyword: 0,
                OpenIndicator: {
                    render: createElement => createElement('div', {class: 'd-none'}),
                }
            };
        },
        computed: {
            filterTypeOptions() {
                var opts = [];
                opts.push({ value: 0, label: "Jahre" });
                opts.push({ value: 1, label: "Personen" });
                opts.push({ value: 2, label: "Schlagworte" });
                return opts;
            },
            filterCategoryOptions() {
                var opts = [];
                this.$store.getters.getCategories.forEach((category, index) => {
                    opts.push({ value: index, label: category.title });
                });
                return opts;
            },
            filterPersonOptions() {
                var opts = [];
                this.$store.getters.getPersons.forEach((person, index) => {
                    opts.push({ value: index, label: person });
                });
                opts.sort((a, b) => a.label > b.label && 1 || -1);
                return opts;
            },
            filterKeywordOptions() {
                var opts = [];
                this.$store.getters.getKeywords.forEach((keyword, index) => {
                    opts.push({ value: index, label: keyword });
                });
                return opts;
            },
            filterValueOptions() {
                var opts = [];
                switch (this.$store.getters.getFilterType) {
                    case 1: opts = this.filterPersonOptions; break;
                    case 2: opts = this.filterKeywordOptions; break;
                    default: opts = this.filterCategoryOptions; break;
                }
                return opts;
            }
        },
        methods: {
            onFilterTypeChange(fType) {
                var fValue = 0;
                switch (fType) {
                    case 1: {
                        fValue = this.selectedPerson;
                        break;
                    }
                    case 2: {
                        fValue = this.selectedKeyword;
                        break;
                    }
                    default: {
                        fValue = this.selectedCategory;
                        break;
                    }
                }
                this.$router.push({ name: 'filter', params: { filterType: fType, filterValue: fValue } });
            },
            onFilterValueChange(fValue) {
                var fType = this.$store.getters.getFilterType;
                switch (fType) {
                    case 1: this.selectedPerson = fValue; break;
                    case 2: this.selectedKeyword = fValue; break;
                    default: this.selectedCategory = fValue; break;
                }       
                this.$router.push({ name: 'filter', params: { filterType: fType, filterValue: fValue } });
            }
        }
    }
</script>
