<template>
    <div class="m-4 h-100" ref="help">
        <h2 class="font-bayon">Hilfe</h2>
        <p class="mt-4">
            Auf dieser Seite versuchen wir die wichtigsten Funktionen der Seite in Kurzform zu erklären.
            Sollten dennoch Fragen zur Bedienung offen bleiben, senden Sie uns gerne eine Nachricht
            über das Feedback Formular.
        </p>
        <div class="m-3">
            <li class="mt-3" v-if="this.$isMobile()">
                <strong>Bild vergrößern</strong><br>
                Klicken Sie doppelt auf eines der Bilder, um es entsprechend Ihrer Bildschirmgröße zu vergrößern.
            </li>
            <li class="mt-3" v-if="this.$isMobile()">
                <strong>Bild verkleinern</strong><br>
                Klicken Sie einmalig auf ein zuvor vergrößertes Bild, um in die Ursprungsansicht zurückzukehren.
            </li>
            <li class="mt-3">
                <strong>Bild in Originalgröße</strong><br>
                <span v-if="this.$isMobile()">
                    Klicken Sie dreifach auf eines der Bilder, um es in Originalgröße in einem neuen Browser-Tab zu öffnen.
                </span>
                <span v-else>
                    Klicken Sie doppelt auf eines der Bilder, um es in Originalgröße in einem neuen Browser-Tab zu öffnen.
                </span>
            </li>
            <li class="mt-3">
                <strong>Bild herunterladen</strong><br>
                <span v-if="this.$isMobile()">
                    Klicken Sie lange auf eines der Bilder und wählen Sie "Bild/Grafik speichern" im neu aufklappenden Menü,
                    um es auf Ihrem Gerät zu speichern.
                </span>
                <span v-else>
                    Klicken Sie mit der rechten Maustaste auf eines der Bilder und wählen Sie "Bild/Grafik speichern unter..."
                    im neu aufklappenden Menü, um es auf Ihrem Gerät zu speichern.
                </span>
            </li>
            <li class="mt-3">
                <strong>Mehr Bilder ein-/ausblenden</strong><br>
                Falls zu einem Eintrag mehr als nur ein Bild vorhanden ist, wird Ihnen beim Seitenaufbau zunächst nur ein
                zufällig ausgewähltes Bild angezeigt. Per Klick auf den Button "Mehr anzeigen..." können Sie anschließend
                alle weiteren Bilder des betreffenden Eintrags ein-/ausblenden.
            </li>
            <li class="mt-3">
                <strong>Personen, bzw. Schlagworte ein-/ausblenden</strong><br>
                Klicken Sie auf den <CIcon name="cil-tags" />-Button, um Direktlinks auf im Bild angezeigte Personen, bzw.
                Inhalt/Schlagworte ein-/auszublenden.
            </li>
            <li class="mt-3">
                <strong>Information zu einem Bild mitteilen</strong><br>
                Falls Sie uns weitere Informationen zu einem Bild zukommen lassen möchten, klicken Sie bitte auf den
                <CIcon name="cil-bullhorn" />-Button unter dem betreffenden Eintrag und füllen das anschließend auftauchende
                Formular möglichst vollständig aus. Vielen Dank für Ihre Hilfe!
            </li>
            <li class="mt-3">
                <strong>Löschung eines Bildes beantragen</strong><br>
                Falls Sie sich oder Jemanden, der Ihnen nahesteht, auf einem der Bilder gefunden haben und nicht mit der
                Veröffentlichung auf dieser Seite einverstanden sind, klicken Sie bitte auf den <CIcon name="cil-bolt" />-Button
                unter dem betreffenden Eintrag und wir werden das Bild nach Prüfung Ihrer Angaben gegebenenfalls entfernen. 
            </li>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VfLHelp',
    mounted() {
        this.$store.dispatch('updateFilter', [-1, 0]);
    }
}
</script>