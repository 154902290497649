<template>
    <div class="m-3 m-lg-4" ref="container">
        <div v-if="this.beforeSubmit">
            <CLink href="javascript:history.back()">
                <CButton
                class="mb-3 border-white text-white"
                color="white"
                shape="square">
                    zurueck
                </CButton>
            </CLink>
            <h3><strong>Löschung beantragen</strong></h3>
            <h6>
                Sie haben sich oder Jemanden, der Ihnen nahesteht, auf einem der Bilder gefunden und sind nicht mit der Veröffentlichung einverstanden?
                Kontaktieren Sie uns und wir werden das Bild nach Prüfung Ihrer Angaben gegebenenfalls entfernen.
            </h6>
            <CRow><CCol sm="12" lg="6"><hr class="mt-3 mb-0 mt-lg-4 mb-lg-2"></CCol></CRow>
            <div v-if="this.selectedEntry !== null" class="mt-3">
                <h4 class="date-item" v-html="this.selectedEntry.caption" />
                <h5 class="title-item" v-html="this.selectedEntry.headline" />
                <p class="mt-3" v-html="this.selectedEntry.images[this.selectedImageIndex]" />
                <h6 v-if="this.selectedEntry.images.length > 1">
                    Meinten Sie ein anderes Bild aus diesem Eintrag?
                    Wählen Sie hier das entsprechende Bild aus:
                </h6>
                <CRow
                    v-if="this.selectedEntry.images.length > 1"
                    class="mt-2">
                    <CCol sm="12" lg="6">
                        <v-select
                            :options="this.selectedEntryOptions"
                            :reduce="(option) => option.value"
                            :value="this.selectedImageIndex"
                            :searchable="false"
                            :clearable="false"
                            @input="onImageChange" />
                    </CCol>
                </CRow>
            </div>
            <CRow><CCol sm="12" lg="6"><hr class="mt-3 mb-0 mt-lg-4 mb-lg-2"></CCol></CRow>
            <CRow class="mt-3">
                <CCol sm="12" lg="6">
                    <CInput
                        :value="this.userName"
                        @input="onUserNameChange"
                        placeholder="Name" />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12" lg="6">
                    <CInput
                        :value="this.userMail"
                        @input="onUserMailChange"
                        placeholder="E-Mail (optional)" />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12" lg="6">
                    <CTextarea
                        :value="this.userMessage"
                        @input="onUserMessageChange"
                        placeholder="Ihre Mitteilung" rows="8" />
                </CCol>
            </CRow>
            <VueRecaptcha
                sitekey="6LebxU4fAAAAADfkjgbmddLCZ6j5OPaGd4lY3aEC"
                :load-recaptcha-script="true"
                @verify="verified = true"
                @error="errorMessage = 'Diese Seite wurde von Menschen für Menschen gemacht.'">
            </VueRecaptcha>
            <h6
                v-if="this.errorMessage != ''"
                class="mt-2"
                style="color: red;">
                {{ this.errorMessage }}
            </h6>
            <CButton
                class="mt-2 border-white text-white"
                color="white"
                shape="square"
                @click="onSubmit">
                Absenden
            </CButton>
        </div>
        <div v-else>
            <h3><strong>Vielen Dank!</strong></h3>
            <h6>Wir werden Ihre Informationen schnellstmöglich prüfen und das Bild gegebenenfalls entfernen.</h6>
        </div>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    name: 'VfLRemove',
    data() {
        return {
            beforeSubmit: true,
            selectedImageIndex: 0,
            userName: '',
            userMail: '',
            userMessage: '',
            errorMessage: '',
            verified: false
        }
    },
    components: {
        VueRecaptcha
    },
    props: {
        selectedEntryID: Number,
        returnFilterType: Number,
        returnFilterValue: Number
    },
    computed: {
        selectedEntry() {
            var entry = this.$store.getters.getDataEntry(this.selectedEntryID);
            if (!entry || entry.caption === '' || entry.headline === '' || entry.image === '')
                return null;
            return entry;
        },
        selectedEntryOptions() {
            var opts = [];
            if (this.selectedEntry === null)
                return opts;
            this.selectedEntry.images.forEach((image, index) => {
                opts.push({ value: index, label: 'Bild ' + (index + 1) });
            });
            return opts;
        }
    },
    mounted() {
        this.$store.commit('setTimelineWidth', [this.$refs.container.clientWidth - 35]);
        this.$store.dispatch('updateFilter', [-1, 0]);
    },
    methods: {
        onImageChange(value) {
            this.selectedImageIndex = value;
        },
        onUserNameChange(value) {
            this.userName = value;
        },
        onUserMailChange(value) {
            this.userMail = value;
        },
        onUserMessageChange(value) {
            this.userMessage = value;
        },
        onReturn() {
            this.$router.push({ name: 'filter', params: { filterType: this.returnFilterType, filterValue: this.returnFilterValue } });
        },
        onSubmit() {
            if (!this.verified) {
                this.errorMessage = 'Bitte bestätigen Sie zunächst, dass Sie kein Roboter sind!';
                return;
            }

            if (this.userName === '' || this.userMessage === '') {
                this.errorMessage = 'Bitte geben Sie mindestens Ihren Namen und eine Mitteilung ein!';
                return;
            }

            this.errorMessage = '';
            var pos0 = this.selectedEntry.images[this.selectedImageIndex].indexOf('src="');
            var pos1 = this.selectedEntry.images[this.selectedImageIndex].indexOf('"', pos0 + 5);
            var imageURI = this.selectedEntry.images[this.selectedImageIndex].substring(pos0 + 5, pos1);
            this.$store.dispatch('submitRemoval', [this.selectedEntry, imageURI, this.userName, this.userMail, this.userMessage]);
            this.beforeSubmit = false;
        }
    }
}
</script>