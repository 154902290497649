<template>
    <div class="m-3 m-lg-4">
        <h2 class="font-bayon">Festschrift</h2>
        <p class="mt-4">
            Stöbern Sie <a href="https://heyzine.com/flip-book/c598e0a7e6.html" target="_blank">hier</a> in unserer liebevoll gestalteten Festschrift.
        </p>
        <p class="mt-3">
            <a href="https://heyzine.com/flip-book/c598e0a7e6.html" target="_blank">
                <img src="@/assets/images/fifty.png" :width="$isMobile() ? '95%' : '400px'" />
            </a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'VfLPublication',
    mounted() {
        this.$store.dispatch('updateFilter', [-1, 0]);
    }
}
</script>