<template>
    <div v-if="$route.name !== 'requestTrack' && $route.name !== 'requestedTracks'" class="c-app">
        <VfLSidebar />
        <CWrapper :class="$route.name !== 'home' ? 'bg-heinrich' : ''">
            <VfLHeader />
            <div class="mx-0 mx-lg-4">
                <router-view id="routerView" ref="routerView" :key="$route.path"></router-view>
            </div>
        </CWrapper>
    </div>
    <div v-else class="c-app">
        <CWrapper class="bg-heinrich text-center">
            <div class="mx-0 mx-lg-4">
                <router-view id="routerView" ref="routerView" :key="$route.path"></router-view>
            </div>
        </CWrapper>
    </div>
</template>

<script>
import VfLSidebar from './components/VfLSidebar.vue'
import VfLHeader from './components/VfLHeader.vue'

export default {
    name: 'App',
    components: {
        VfLSidebar,
        VfLHeader,
    },
    created() {
        this.$store.dispatch('initStoryblok');
    }
}
</script>

<style lang="scss">
@import 'assets/styles/style';
</style>