<template>
    <CSidebar ref="sidebar" fixed :minimize="minimize" :show="show" @update:show="(value) => $store.commit('setSidebarShow', [value])">
        <CSidebarBrand to="/">
            <div class="w-100 text-center my-3">
                <img :class="minimize ? 'm-0' : 'm-2'" src="@/assets/images/wappen.png" :width="minimize ? '80%' : '65%'" />
                <span class="c-sidebar-brand-full">
                    <h5 class="mb-0 mt-1 font-bayon"><i>50 Jahre</i></h5>
                    <h4 class="mb-0 mt-1 font-bayon"><strong>VfL Hameln Basketball</strong></h4>
                    <h6 class="mb-0 mt-1 font-bayon">Eine Chronik in Bildern</h6>
                </span>
            </div>
        </CSidebarBrand>

        <CSidebarNav class="pt-2">
            <CSidebarNavItem icon="cilHome" name="Home" to="/" />
            <CSidebarNavDropdown
                class="d-none d-lg-block"
                style="border-top: 2px solid #1e2846;"
                icon="cilBook"
                name="Chronik"
                route="/chronicle"
                show>
                <CSidebarNavItem
                    v-for="(item, index) in this.$store.getters.getCategories" :key="index"
                    :name="item.title"
                    :active="isActiveLink(index)"
                    :to="{ name: 'filter', params: { filterType: 0, filterValue: index } }" />
            </CSidebarNavDropdown>
            <CSidebarNavItem class="d-lg-none" icon="cilBook" name="Chronik" to="/chronicle" />
            <CSidebarNavItem v-for="(item, index) in this.$store.getters.getNonChronicleKeywords"
                icon="cilStar"
                :key="index"
                :name="item"
                :to="{ name: 'filter', params: { filterType: 3, filterValue: $store.getters.getKeywordIndex(item) } }" />
            <CSidebarNavItem icon="cilUser" name="Personen" to="/persons" />
            <CSidebarNavItem icon="cilFilter" name="Schlagworte" to="/keywords" />
            <CSidebarNavItem icon="cilStar" name="Neueste Beitraege" to="/recent" />
            <CSidebarNavItem icon="cilPeople" name="Wer war dabei?" to="/archive" />
            <hr class="c-sidebar-divider-alt" />
            <hr class="c-sidebar-divider-alt" style="border-top: 1px solid #08355e;" />
            <CSidebarNavItem icon="cilSpreadsheet" name="Festschrift" to="/publication" />
            <hr class="c-sidebar-divider-alt" />
            <hr class="c-sidebar-divider-alt" style="border-top: 1px solid #08355e;" />
            <CSidebarNavItem icon="cilInfo" name="Hilfe" to="/help" />
            <CSidebarNavItem icon="cilEnvelopeClosed" name="Feedback" to="/feedback" />
            <CSidebarNavItem icon="cilThumbUp" name="Kooperation" to="/credits" />
            <CSidebarNavItem icon="cilLibraryBuilding" name="Impressum" to="/imprint" />
            <CSidebarNavItem icon="cilLockLocked" name="Datenschutz" to="/privacy" />
        </CSidebarNav>

        <CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('setSidebarMinimize', [!minimize])" />
    </CSidebar>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
    name: 'VfLSidebar',
    computed: {
        show () {
            return this.$store.getters.getViewSidebarShow
        },
        minimize () {
            return this.$store.getters.getViewSidebarMinimize
        }
    },
    watch: {
        show() {
            if (this.$isMobile()) {
                if (this.show === true) {
                    disableBodyScroll(this.$refs.sidebar);
                }
                else {
                    enableBodyScroll(this.$refs.sidebar);
                }
            }
        }
    },
    methods: {
        isActiveLink(index) {
            return this.$store.getters.getCurrentCategory === index;
        }
    }
}
</script>
