<template>
    <div class="m-4 h-100" ref="credits">
        <h2 class="font-bayon">Kooperation</h2>
        <p class="mt-4">
            Im Namen der Basketballer der letzten 50 Jahre möchten wir uns an
            dieser Stelle ganz herzlich bei unserem langjährigen Partner
            <a class="text-white" href="https://www.dewezet.de" target="_blank"><u>DEWEZET</u></a> bedanken!
        </p>
        <p class="mt-4">
            <a class="text-white" href="https://www.dewezet.de" target="_blank">
                <img src="@/assets/images/dewezet.png" :width="this.$isMobile() ? '90%' : ''" />
            </a>
        </p>
        <p class="mt-4">
            Auch für das Projekt, die 50-jährige Geschichte des Basketballs im VfL Hameln zu digitalisieren
            und an dieser Stelle zu präsentieren, haben wir volle Unterstützung und eine Nutzungserlaubnis
            für die Artikel der letzten 50 Jahre, die sich mit diesem Thema beschäftigen, erhalten.
        </p>
        <h3 class="mt-4 font-bayon">
            VIELEN DANK!
        </h3>
    </div>
</template>

<script>
export default {
    name: 'VfLCredits',
    mounted() {
        this.$store.dispatch('updateFilter', [-1, 0]);
    }
}
</script>