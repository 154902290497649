<template>
    <div class="m-3 m-lg-4">
        <h2 class="font-bayon">Datenschutz</h2>
        <p class="mt-4">
            Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen
            der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen
            Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.
        </p>
        <p class="mt-3">
            Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung
            (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter
            Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten,
            wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung
            oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
            Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung,
            die Einschränkung, das Löschen oder die Vernichtung.
        </p>
        <p class="mt-3">
            Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck,
            Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder
            gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir
            Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität
            eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.
        </p>

        <p class="mt-5">
            Unsere Datenschutzerklärung ist wie folgt gegliedert:<br>
            <strong>
                I. Informationen über uns als Verantwortliche<br>
                II. Rechte der Nutzer und Betroffenen<br>
                III. Informationen zur Datenverarbeitung
            </strong>
        </p>

        <h5 class="mt-5"><strong>I. Informationen über uns als Verantwortliche</strong></h5>
        <p>
            Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:<br><br>
            Žans Lutter<br>
            Buchenwinkel 6<br>
            31789 Hameln<br>
            Deutschland<br><br>
            Telefon: 0176/64962257<br>
            E-Mail: <a href="mailto:info@50jahrebasketball.de">info@50jahrebasketball.de</a><br><br>
            
            <strong>Datenschutzbeauftragte/r beim Anbieter ist:</strong><br>
            Žans Lutter
        </p>

        <h5 class="mt-5"><strong>II. Rechte der Nutzer und Betroffenen</strong></h5>
        <p>
            Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht
            <ul>
                <li>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</li>
                <li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</li>
                <li>auf unverzügliche Löschung (innerhalb von 72 Stunden) der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</li>
                <li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
                <li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</li>
            </ul>
        </p>
        <p>
            Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter
            offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der
            Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht
            jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist.
            Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.
        </p>
        <p>
            <strong>
                Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die
                künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe
                von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die
                Datenverarbeitung zum Zwecke der Direktwerbung statthaft.
            </strong>
        </p>
        
        <h5 class="mt-5"><strong>III. Informationen zur Datenverarbeitung</strong></h5>
        <p>
            Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt,
            sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten
            entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.
        </p>
        <div class="mt-3">
            <strong>Serverdaten</strong>
            <p>
                Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts,
                werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog.
                Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website,
                von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres
                Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des
                Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.
            </p>
            <p>
                Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.
            </p>
            <p>
                Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse
                liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.
            </p>
            <p>
                Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken
                erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise
                von der Löschung ausgenommen.
            </p>
        </div>
        <div class="mt-3">
            <strong>Cookies</strong>
            <p>
                <i>a) Sitzungs-Cookies/Session-Cookies</i><br>
                Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien,
                die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch diese Cookies
                werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten
                oder Ihre IP-Adresse, verarbeitet. Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver
                und sicherer. Da die Verarbeitung weder der Vertragsanbahnung, noch der Vertragsabwicklung dient, liegt unser berechtigtes
                Interesse in der Verbesserung der Funktionalität unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO.
                Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.
            </p>
            <p>
                <i>b) Drittanbieter-Cookies</i><br>
                Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung,
                der Analyse oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet. Die Einzelheiten hierzu,
                insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie
                bitte den nachfolgenden Informationen.
            </p>
            <p>
                <i>c) Beseitigungsmöglichkeit</i><br>
                Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken.
                Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen
                hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion
                oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies
                kann die Verarbeitung allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen
                Sie insoweit die Einstellung Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen
                von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder
                Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support. Sollten Sie die
                Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche
                Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.
            </p>
        </div>
        
        <div class="mt-3">
            <strong>Tracking</strong>
            <p>
                Diese Seite verwendet keinerlei Dienste von Drittanbietern zur Erstellung von Nutzungsstatistiken.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VfLPrivacy',
        mounted() {
            this.$store.dispatch('updateFilter', [-1, 0]);
        }
    }
</script>