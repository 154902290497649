import Vue from 'vue'
import Router from 'vue-router'

import VfLHome from '../components/VfLHome.vue'
import VfLTimeline from '../components/VfLTimeline.vue'
import VfLRecent from '../components/VfLRecent.vue'
import VfLArchive from '../components/VfLArchive.vue'
import VfLPublication from '../components/VfLPublication.vue'
import VfLReport from '../components/VfLReport.vue'
import VfLRemove from '../components/VfLRemove.vue'
import VfLShare from '../components/VfLShare.vue'
import VfLHelp from '../components/VfLHelp.vue'
import VfLFeedback from '../components/VfLFeedback.vue'
import VfLCredits from '../components/VfLCredits.vue'
import VfLPrivacy from '../components/VfLPrivacy.vue'
import VfLImprint from '../components/VfLImprint.vue'
import VfLRequestTrack from '../components/VfLRequestTrack.vue'
import VfLRequestedTracks from '../components/VfLRequestedTracks.vue'

Vue.use(Router)

export default new Router({
    abstract: true,
    routes: configRoutes(),
    linkActiveClass: 'active',
    mode: 'hash',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        else
            return { x: 0, y: 0, behavior: 'smooth' };
    }
})

function configRoutes () {
    return [
        {
            path: '/',
            name: 'home',
            component: VfLHome
        },
        {
            path: '/chronicle',
            name: 'chronicle',
            component: VfLTimeline,
            props: {
                filterType: 0,
                filterValue: 0
            }
        },
        {
            path: '/recent',
            name: 'recent',
            component: VfLRecent
        },
        {
            path: '/persons',
            name: 'persons',
            component: VfLTimeline,
            props: {
                filterType: 1,
                filterValue: 0
            }
        },
        {
            path: '/keywords',
            name: 'keywords',
            component: VfLTimeline,
            props: {
                filterType: 2,
                filterValue: 0
            }
        },
        {
            path: '/archive',
            name: 'archive',
            component: VfLArchive
        },
        {
            path: '/filter/:filterType/:filterValue',
            name: 'filter',
            component: VfLTimeline,
            props: ({params}) => ({
                filterType: Number.parseInt(params.filterType, 10),
                filterValue: Number.parseInt(params.filterValue, 10)
            })
        },
        {
            path: '/publication',
            name: 'publication',
            component: VfLPublication
        },
        {
            path: '/report/:selectedEntryID',
            name: 'report',
            component: VfLReport,
            props: ({params}) => ({
                selectedEntryID: Number.parseInt(params.selectedEntryID, 10),
                returnFilterType: Number.parseInt(params.returnFilterType, 10),
                returnFilterValue: Number.parseInt(params.returnFilterValue, 10)
            })
        },
        {
            path: '/reportArchive/:selectedEntryID',
            name: 'reportArchive',
            component: VfLReport,
            props: ({params}) => ({
                archiveEntry: true,
                selectedEntryID: Number.parseInt(params.selectedEntryID, 10),
                returnFilterType: Number.parseInt(params.returnFilterType, 10),
                returnFilterValue: Number.parseInt(params.returnFilterValue, 10)
            })
        },
        {
            path: '/remove/:selectedEntryID',
            name: 'remove',
            component: VfLRemove,
            props: ({params}) => ({
                selectedEntryID: Number.parseInt(params.selectedEntryID, 10),
                returnFilterType: Number.parseInt(params.returnFilterType, 10),
                returnFilterValue: Number.parseInt(params.returnFilterValue, 10)
            })
        },
        {
            path: '/share/:selectedEntryIndex',
            name: 'share',
            component: VfLShare,
            props: ({params}) => ({
                selectedEntryIndex: Number.parseInt(params.selectedEntryIndex, 10)
            })
        },
        {
            path: '/help',
            name: 'help',
            component: VfLHelp
        },
        {
            path: '/feedback',
            name: 'feedback',
            component: VfLFeedback
        },
        {
            path: '/credits',
            name: 'credits',
            component: VfLCredits
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: VfLPrivacy
        },
        {
            path: '/imprint',
            name: 'imprint',
            component: VfLImprint
        },
        {
            path: '/requestTrack',
            name: 'requestTrack',
            component: VfLRequestTrack
        },
        {
            path: '/requestedTracks',
            name: 'requestedTracks',
            component: VfLRequestedTracks
        }
    ]
}