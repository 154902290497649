<template>
    <div class="m-3 m-lg-4">
        <div v-if="this.beforeSubmit">
            <h2 class="font-bayon">Feedback</h2>
            <p class="mt-4">
                Auf dieser Seite können Sie uns ein <strong>individuelles Feedback</strong> geben.
            </p>
            <p class="mt-3">
                Sollten Sie Fragen oder Anmerkungen zu einzelnen oder mehreren Einträgen haben,
                können Sie auch einfach auf den <strong><CIcon name="cil-bullhorn" /></strong>-Button
                unter dem entsprechenden Eintrag klicken. Dadurch fällt es uns leichter, Ihre Mitteilung
                dem betreffenden Eintrag zuzuordnen.
            </p>
            <p class="mt-3">
                Sollten Sie sich oder Jemanden, der Ihnen nahesteht, auf einem der Bilder gefunden haben
                und nicht mit der Veröffentlichung auf dieser Seite einverstanden sein, klicken Sie bitte
                auf den <strong><CIcon name="cil-bolt" /></strong>-Button unter dem entsprechenden Eintrag
                und wir werden das Bild nach Prüfung Ihrer Angaben gegebenenfalls entfernen.
            </p>
            <p class="mt-3">
                <strong>Vielen Dank im Voraus für Ihr Feedback!</strong>
            </p>
            <CRow class="mt-3">
                <CCol sm="12" lg="6">
                    <CInput
                        :value="this.userName"
                        @input="onUserNameChange"
                        placeholder="Name" />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12" lg="6">
                    <CInput
                        :value="this.userMail"
                        @input="onUserMailChange"
                        placeholder="E-Mail (optional)" />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12" lg="6">
                    <CTextarea
                        :value="this.userMessage"
                        @input="onUserMessageChange"
                        placeholder="Ihr Feedback" rows="8" />
                </CCol>
            </CRow>
            <VueRecaptcha
                sitekey="6LebxU4fAAAAADfkjgbmddLCZ6j5OPaGd4lY3aEC"
                :load-recaptcha-script="true"
                @verify="verified = true"
                @error="errorMessage = 'Diese Seite wurde von Menschen für Menschen gemacht.'">
            </VueRecaptcha>
            <h6
                v-if="this.errorMessage != ''"
                class="mt-2"
                style="color: red;">
                {{ this.errorMessage }}
            </h6>
            <CButton
                class="mt-2 border-white text-white"
                color="white"
                shape="square"
                @click="onSubmit">
                Absenden
            </CButton>
        </div>
        <div v-else>
            <h3><strong>Vielen Dank!</strong></h3>
            <h6>Wir werden Ihre Informationen schnellstmöglich prüfen und die Seite gegebenenfalls aktualisieren.</h6>
        </div>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    name: 'VfLFeedback',
    data() {
        return {
            beforeSubmit: true,
            userName: '',
            userMail: '',
            userMessage: '',
            errorMessage: '',
            verified: false
        }
    },
    components: {
        VueRecaptcha
    },
    mounted() {
        this.$store.dispatch('updateFilter', [-1, 0]);
    },
    methods: {
        onUserNameChange(value) {
            this.userName = value;
        },
        onUserMailChange(value) {
            this.userMail = value;
        },
        onUserMessageChange(value) {
            this.userMessage = value;
        },
        onSubmit() {
            if (!this.verified) {
                this.errorMessage = 'Bitte bestätigen Sie zunächst, dass Sie kein Roboter sind!';
                return;
            }

            if (this.userName === '' || this.userMessage === '') {
                this.errorMessage = 'Bitte geben Sie mindestens Ihren Namen und eine Mitteilung ein!';
                return;
            }

            this.errorMessage = '';
            this.$store.dispatch('submitFeedback', [this.userName, this.userMail, this.userMessage]);
            this.beforeSubmit = false;
        }
    }
}
</script>