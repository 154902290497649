import 'core-js/stable'
import Vue from 'vue'
import vSelect from 'vue-select'
import App from './App.vue'
import store from '@/store'
import router from '@/router'
import CoreuiVue from '@coreui/vue'
import StoryblokVue from 'storyblok-vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import VueMobileDetection from 'vue-mobile-detection'

import 'vue-select/dist/vue-select.css'

Vue.use(CoreuiVue);
Vue.use(StoryblokVue);
Vue.use(VueMobileDetection);

Vue.component('v-select', vSelect);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  icons,
  render: h => h(App),
  components: {
      App
  }
}).$mount('#app')
