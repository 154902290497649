<template>
    <div class="m-3 m-lg-4" ref="share">
        <div
            v-if="selectedEntry !== null"
            class="item">
            <h3 class="date-item" v-html="selectedEntry.caption" />
            <h4 class="title-item" v-html="selectedEntry.title" />
            <p class="description-item mb-3" v-if="!showIntroLong">
                <span v-html="selectedEntry.introShort" />
                <CLink
                    class="ml-2 text-white"
                    @click="showIntroLong = !showIntroLong">
                    <u>Weiterlesen</u>
                </CLink>
            </p>
            <p class="description-item mb-3" v-else>
                <span v-html="selectedEntry.introLong" />
                <CLink
                    v-if="selectedEntry.introShort.length > 0"
                    class="ml-2 text-white"
                    @click="showIntroLong = !showIntroLong">
                    <u>Text ausblenden</u>
                </CLink>
            </p>
            <p
                :id="'description-' + selectedEntry.id"
                class="description-item"
                v-html="selectedEntry.description" />
            <CCollapse
                v-if="selectedEntry.additionalNum > 0"
                class="mt-3"
                :show="showAdditional">
                <p class="description-item" v-html="selectedEntry.additional" />
            </CCollapse>
            <div
                v-if="selectedEntry.videoURL !== undefined &&
                      selectedEntry.videoURL !== null &&
                      selectedEntry.videoURL !== ''"
                :id="'video-' + selectedEntry.id">
                <h5
                    v-if="selectedEntry.videoHeadline !== undefined"
                    class="mb-1">
                    <strong>
                        {{ selectedEntry.videoHeadline }}
                    </strong>
                </h5>
                <p
                    v-if="selectedEntry.videoDescription !== undefined"
                    v-html="selectedEntry.videoDescription" />
                <iframe
                    :src="selectedEntry.videoURL"
                    :title="selectedEntry.videoHeadline"
                    :width="videoWidth"
                    :height="videoHeight"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>
            <CCollapse
                v-if="selectedEntry.tags.length > 0"
                class="mt-3"
                :show="showTags">
                <p class="description-item" v-html="selectedEntry.tags" />
            </CCollapse>
            <CAlert
                color="secondary"
                class="px-3 py-2"
                :show.sync="selectedEntry.hintCounter">
                Link in die Zwischenablage kopiert.
                <CProgress
                    :max="3"
                    :value="selectedEntry.hintCounter"
                    height="3px"
                    color="secondary"
                    animate
                />
            </CAlert>
            <CButtonToolbar class="mt-3" justify>
                <CButton
                    v-if="selectedEntry.additionalNum > 0"
                    class="mr-2 border-white text-white"
                    color="white"
                    shape="square"
                    size="sm"
                    @click="onAdditionalShowClick">
                    {{ (showAdditional ? 'Nicht mehr anzeigen' : ('Mehr anzeigen... (+' + selectedEntry.additionalNum + ')')) }}
                </CButton>
                <CButton
                    v-if="selectedEntry.tags.length > 0"
                    class="mr-2 border-white text-white"
                    color="white"
                    shape="square"
                    size="sm"
                    :title="'Tags ' + (showTags ? 'ausblenden' : 'anzeigen')"
                    @click="showTags = !showTags">
                    <CIcon name="cil-tags" />
                </CButton>
                <CButton
                    class="mr-2 border-white text-white"
                    color="white"
                    shape="square"
                    size="sm"
                    title="Eintrag teilen"
                    @click="onShareClick">
                    <CIcon name="cil-share" />
                </CButton>
                <CButton
                    class="mr-2 border-white text-white"
                    color="white"
                    shape="square"
                    size="sm"
                    title="Informationen mitteilen"
                    @click="$router.push({
                        name: 'report',
                        params: { 
                            selectedEntryID: selectedEntry.id,
                            returnFilterType: $store.getters.getFilterType,
                            returnFilterValue: $store.getters.getFilterValue
                        }
                    });">
                    <CIcon name="cil-bullhorn" />
                </CButton>
                <CButton
                    class="border-white text-white"
                    color="white"
                    shape="square"
                    size="sm"
                    title="Löschung beantragen"
                    @click="$router.push({
                        name: 'remove',
                        params: { 
                            selectedEntryID: selectedEntry.id,
                            returnFilterType: $store.getters.getFilterType,
                            returnFilterValue: $store.getters.getFilterValue
                        }
                    });">
                    <CIcon name="cil-bolt" />
                </CButton>
            </CButtonToolbar>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VfLShare',
    props: {
        selectedEntryIndex: Number
    },
    data() {
        return {
            showIntroLong: false,
            showTags: false,
            showAdditional: false
        }
    },
    computed: {
        selectedEntry() {
            const entries = this.$store.getters.getViewEntries;
            if (entries.length === 0)
                return null;
            return entries[0];
        }
    },
    watch: {
        selectedEntry: function(entry) {
            this.showIntroLong = entry.introLongShow;
            this.showTags = entry.tagsShow;
            this.showAdditional = entry.additionalShow;
        }
    },
    mounted() {
        this.$store.commit('setTimelineWidth', [this.$refs.share.clientWidth - 35]);
        this.$store.dispatch('updateFilter', [3, this.selectedEntryIndex]);
    },
    methods: {
        onAdditionalShowClick() {
            this.showAdditional = !this.showAdditional;
            if (!this.showAdditional) {
                var element = document.getElementById('description-' + this.selectedEntry.id);
                window.scrollTo(0, element.offsetTop + element.parentElement.offsetTop);
            }
        },
        onShareClick() {
            let props = this.$router.resolve({ 
                name: 'share',
                params: { selectedEntryIndex: this.selectedEntry.index }
            });
            let url = location.origin + '/' + props.href;
            let copyClipboard = false;

            if (!navigator.share)
                copyClipboard = true;
            else {
                navigator.share({
                    title: this.selectedEntry.title,
                    text: 'Schau dir diesen Beitrag auf 50jahrebasketball.de an!',
                    url: url
                })
                .catch(() => copyClipboard = true);
            }

            if (copyClipboard) {
                navigator.clipboard.writeText(url);
                this.selectedEntry.hintCounter = 3;
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.item {
    padding: 20px 0 20px 15px;
    position: relative;
}
.date-item {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
}
.title-item {
    margin: 0;
    padding: 5px 0;
    font-size: 20px;
    font-weight: 500;
}
.description-item {
    font-weight: 100;
    margin: 0;
}
</style>
