<template>
    <div class="m-3 m-lg-4" ref="container">
        <h1 class="font-bayon">Dein Sumpfblume-Song</h1>
        <p v-if="this.live" class="mt-4">
            Eingegangene Musikwünsche <strong>WÄHREND</strong> der Party:
        </p>
        <p v-else class="mt-4">
            Eingegangene Musikwünsche <strong>VOR</strong> der Party:
        </p>
        <CRow align-horizontal="center" class="my-4">
            <CCol sm="12" lg="6">
                <CDataTable
                    :items="trackRequests"
                    :fields="fields"
                    size="sm"
                    dark
                    hover
                    sorter
                    :sorterValue="{ column: 'creationTimestamp', asc: false }"
                    :itemsPerPage="20"
                    pagination>
                    <template #creationTimestamp="{item}">
                        <td>
                            {{ new Date(item.creationTimestamp * 1000).toLocaleString() }}
                        </td>
                    </template>
                </CDataTable>
            </CCol>
        </CRow>
        <CButton
            class="border-white text-white"
            color="white"
            shape="square"
            @click="onButtonClick">
            {{ this.live ? "--- VORHER ---" : '--- LIVE ---' }}
        </CButton>
    </div>
</template>

<script>
export default {
    name: 'VfLRequestedTracks',
    data() {
        return {
            live: false,
            fields: [
                { key: 'creationTimestamp', label: 'Erstellt Am' },
                { key: 'artist', label: 'Interpret' },
                { key: 'track', label: 'Titel' }
            ]
        }
    },
    mounted() {
        this.updateTrackRequests();
    },
    computed: {
        trackRequests() {
            return this.$store.getters.getDataTrackRequests;
        }
    },
    methods: {
        onButtonClick() {
            this.live = !this.live;
            this.$store.dispatch('fetchTrackRequests', [this.live]);
        },
        updateTrackRequests() {
            this.$store.dispatch('fetchTrackRequests', [this.live]);
            setTimeout(() => { this.updateTrackRequests(); }, 10000);
        }
    }
}
</script>