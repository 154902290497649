<template>
    <div class="m-4 h-100" ref="home">
        <h2 class="font-bayon">Herzlich Willkommen</h2>
        <p class="mt-4">
            Anlässlich unseres <strong>50. Geburtstags</strong> präsentieren wir Ihnen auf dieser Seite
            eine Chronik der Basketball-Abteilung des VfL Hameln in Form von Bildern,
            Texten und Zeitungsartikeln.
        </p>
        <p class="mt-4">
            Sollten Sie Fragen oder Anmerkungen zu einzelnen oder mehreren Einträgen haben,
            zögern Sie bitte nicht uns zu kontaktieren. Klicken Sie dazu einfach auf den <strong><CIcon name="cil-bullhorn" /></strong>-Button
            unter dem entsprechenden Eintrag. Wir freuen uns über jegliche Informationen, die uns helfen diese Seite
            stetig zu verbessern.
        </p>
        <img :class="this.$isMobile() ? 'w-100' : 'w-50'" class="mt-4 mb-0" src="heinrich-no-transparency.png" />
        <hr class="mt-0" />
        <p class="mt-4">
            Sollten Sie sich oder Jemanden, der Ihnen nahesteht, auf einem der Bilder gefunden haben
            und nicht mit der Veröffentlichung auf dieser Seite einverstanden sein, klicken Sie bitte
            auf den <strong><CIcon name="cil-bolt" /></strong>-Button unter dem entsprechenden Eintrag
            und wir werden das Bild nach Prüfung Ihrer Angaben gegebenenfalls entfernen.
        </p>
        <p class="mt-4">
            <strong>Wir wünschen viel Spaß beim Stöbern!</strong>
        </p>
        <CLink to="/chronicle">
            <CButton
                class="mt-3 border-white text-white"
                color="white"
                shape="square">
                Let's go!
            </CButton>
        </CLink>
    </div>
</template>

<script>
export default {
    name: 'VfLHome',
    mounted() {
        this.$store.dispatch('updateFilter', [-1, 0]);
        this.$store.commit('setTimelineWidth', [this.$refs.home.clientWidth - 20]);
    }
}
</script>