import {
  cilBasketball,
  cilBolt,
  cilBook,
  cilBullhorn,
  cilEnvelopeClosed,
  cilFilter,
  cilInfo,
  cilHandPointUp,
  cilHappy,
  cilHeart,
  cilHome,
  cilLibraryBuilding,
  cilLockLocked,
  cilMenu,
  cilPeople,
  cilShare,
  cilSpreadsheet,
  cilStar,
  cilUser,
  cilTags,
  cilThumbUp
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilBasketball,
    cilBolt,
    cilBook,
    cilBullhorn,
    cilEnvelopeClosed,
    cilFilter,
    cilHandPointUp,
    cilHappy,
    cilHeart,
    cilHome,
    cilInfo,
    cilLibraryBuilding,
    cilLockLocked,
    cilMenu,
    cilPeople,
    cilShare,
    cilSpreadsheet,
    cilStar,
    cilUser,
    cilTags,
    cilThumbUp
  }
)
