<template>
  <section class="timeline-item" :id="'timeline-item-' + itemTimeline.id">
    <div class="item">
      <span :style="getBackgroundColour(itemTimeline.color)" class="dot" />
      <h3 class="date-item" v-html="itemTimeline.caption" />
      <h4 class="title-item" v-html="itemTimeline.title" />
      <p class="description-item mb-3" v-if="!showIntroLong">
          <span v-html="itemTimeline.introShort" />
          <CLink
              class="ml-2 text-white"
              @click="showIntroLong = !showIntroLong">
              <u>Weiterlesen</u>
          </CLink>
      </p>
      <p class="description-item mb-3" v-else>
          <span v-html="itemTimeline.introLong" />
          <CLink
              v-if="itemTimeline.introShort.length > 0"
              class="ml-2 text-white"
              @click="showIntroLong = !showIntroLong">
              <u>Text ausblenden</u>
          </CLink>
      </p>
      <p
          :id="'description-' + itemTimeline.id"
          class="description-item"
          v-html="itemTimeline.description" />
      <CCollapse
          v-if="itemTimeline.additionalNum > 0"
          class="mt-3"
          :show="showAdditional">
        <p class="description-item" v-html="itemTimeline.additional" />
      </CCollapse>
      <div
          v-if="itemTimeline.videoURL !== undefined &&
                itemTimeline.videoURL !== null &&
                itemTimeline.videoURL !== ''"
          :id="'video-' + itemTimeline.id">
          <h5
              v-if="itemTimeline.videoHeadline !== undefined"
              class="mb-1">
              <strong>
                  {{ itemTimeline.videoHeadline }}
              </strong>
          </h5>
          <p
              v-if="itemTimeline.videoDescription !== undefined"
              v-html="itemTimeline.videoDescription" />
          <iframe
              :src="itemTimeline.videoURL"
              :title="itemTimeline.videoHeadline"
              :width="videoWidth"
              :height="videoHeight"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
          </iframe>
      </div>
      <CCollapse
          v-if="itemTimeline.tags.length > 0"
          class="mt-3"
          :show="showTags">
        <p class="description-item" v-html="itemTimeline.tags" />
      </CCollapse>
      <CAlert
          color="secondary"
          class="px-3 py-2"
          :show.sync="hintCounter">
          Link in die Zwischenablage kopiert.
          <CProgress
            :max="3"
            :value="hintCounter"
            height="3px"
            color="secondary"
            animate
          />
      </CAlert>
      <CButtonToolbar class="mt-3" justify>
        <CButton
            v-if="itemTimeline.additionalNum > 0"
            class="mr-2 border-white text-white"
            color="white"
            shape="square"
            size="sm"
            @click="onAdditionalShowClick">
          {{ (showAdditional ? 'Nicht mehr anzeigen' : ('Mehr anzeigen... (+' + itemTimeline.additionalNum + ')')) }}
        </CButton>
        <CButton
          v-if="itemTimeline.tags.length > 0"
          class="mr-2 border-white text-white"
          color="white"
          shape="square"
          size="sm"
          :title="'Tags ' + (showTags ? 'ausblenden' : 'anzeigen')"
          @click="showTags = !showTags">
          <CIcon name="cil-tags" />
        </CButton>
        <CButton
          class="mr-2 border-white text-white"
          color="white"
          shape="square"
          size="sm"
          title="Eintrag teilen"
          @click="onShareClick">
          <CIcon name="cil-share" />
        </CButton>
        <CButton
          class="mr-2 border-white text-white"
          color="white"
          shape="square"
          size="sm"
          title="Informationen mitteilen"
          @click="$router.push({
            name: 'report',
            params: { 
              selectedEntryID: itemTimeline.id,
              returnFilterType: $store.getters.getFilterType,
              returnFilterValue: $store.getters.getFilterValue
            }
          });">
          <CIcon name="cil-bullhorn" />
        </CButton>
        <CButton
          class="border-white text-white"
          color="white"
          shape="square"
          size="sm"
          title="Löschung beantragen"
          @click="$router.push({
            name: 'remove',
            params: { 
              selectedEntryID: itemTimeline.id,
              returnFilterType: $store.getters.getFilterType,
              returnFilterValue: $store.getters.getFilterValue
            }
          });">
          <CIcon name="cil-bolt" />
        </CButton>
      </CButtonToolbar>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TimelineItem',
  props: {
    itemTimeline: {
      type: Object,
      default: () => ({})
    },
    colorDots: {
      type: String,
      default: '#2da1bf'
    },
    dateLocale: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showIntroLong: false,
      showTags: false,
      showAdditional: false,
      hintCounter: 0
    }
  },
  computed: {
    videoWidth() {
      let width = this.$store.getters.getTimelineWidth;
      if (width > 540)
        width = 540;
      return width;
    },
    videoHeight() {
      return this.videoWidth * 0.5625;
    }
  },
  mounted() {
    this.showIntroLong = this.itemTimeline.introLongShow;
    this.showTags = this.itemTimeline.tagsShow;
    this.showAdditional = this.itemTimeline.additionalShow;
  },
  methods: {
    getBackgroundColour(color) {
      return color ? `background:${color}` : `background:${this.colorDots}`
    },
    onAdditionalShowClick() {
      this.showAdditional = !this.showAdditional;
      if (!this.showAdditional) {
        var element = document.getElementById('description-' + this.itemTimeline.id);
        window.scrollTo(0, element.offsetTop + element.parentElement.offsetTop);
      }
    },
    onShareClick() {
      let props = this.$router.resolve({ 
        name: 'share',
        params: { selectedEntryIndex: this.itemTimeline.index }
      });
      let url = location.origin + '/' + props.href;
      let copyClipboard = false;

      if (!navigator.share)
          copyClipboard = true;
      else {
        navigator.share({
          title: this.itemTimeline.title,
          text: 'Schau dir diesen Beitrag auf 50jahrebasketball.de an!',
          url: url
        })
        .catch(() => copyClipboard = true);
      }

      if (copyClipboard) {
        navigator.clipboard.writeText(url);
        this.hintCounter = 3;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline-item {
  .item {
    border-left: 5px solid #ccd5db;
    padding: 20px 0 20px 15px;
    position: relative;
  }
  .date-item {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .title-item {
    margin: 0;
    padding: 5px 0;
    font-size: 20px;
    font-weight: 500;
  }
  .description-item {
    font-weight: 100;
    margin: 0;
  }
  .dot {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    left: -10px;
    top: 26px;
  }
}
</style>
