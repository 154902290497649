<template>
    <div class="m-3 m-lg-4" ref="container">
        <CRow align-horizontal="center" class="mb-4">
            <CCol sm="12" lg="6">
                <img src="@/assets/images/wappen.png" :width="$isMobile() ? '50%' : '25%'" />
            </CCol>
        </CRow>
        <div v-if="this.beforeSubmit">
            <h1 class="font-bayon">Dein Sumpfblume-Song</h1>
            <p class="mt-4">
                Teile uns deinen Lieblingssong schon im Vorfeld mit, damit unser DJ ihn in seine Playlist aufnehmen kann.
            </p>
            <CRow align-horizontal="center">
                <CCol sm="12" lg="6"><hr class="mt-3 mb-0 mt-lg-4 mb-lg-2"></CCol>
            </CRow>
            <CRow align-horizontal="center" class="mt-3">
                <CCol sm="12" lg="6">
                    <CInput
                        :value="this.artist"
                        @input="onArtistChange"
                        placeholder="Interpret" />
                </CCol>
            </CRow>
            <CRow align-horizontal="center">
                <CCol sm="12" lg="6">
                    <CInput
                        :value="this.track"
                        @input="onTrackChange"
                        placeholder="Titel" />
                </CCol>
            </CRow>
            <CRow align-horizontal="center">
                <VueRecaptcha
                    sitekey="6LebxU4fAAAAADfkjgbmddLCZ6j5OPaGd4lY3aEC"
                    :load-recaptcha-script="true"
                    @verify="verified = true"
                    @error="errorMessage = 'Diese Seite wurde von Menschen für Menschen gemacht.'">
                </VueRecaptcha>
            </CRow>
            <h6
                v-if="this.errorMessage != ''"
                class="mt-2"
                style="color: red;">
                {{ this.errorMessage }}
            </h6>
            <CButton
                class="mt-4 border-white text-white"
                color="white"
                shape="square"
                @click="onSubmit">
                Absenden
            </CButton>
        </div>
        <div v-else>
            <h1><strong>Vielen Dank!</strong></h1>
            <p class="mt-4">
                Wir werden deinen Wunsch an unseren DJ weiterleiten.
            </p>
        </div>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    name: 'VfLRequestTrack',
    data() {
        return {
            beforeSubmit: true,
            artist: '',
            track: '',
            errorMessage: '',
            verified: false
        }
    },
    components: {
        VueRecaptcha
    },
    methods: {
        onArtistChange(value) {
            this.artist = value;
        },
        onTrackChange(value) {
            this.track = value;
        },
        onSubmit() {
            if (!this.verified) {
                this.errorMessage = 'Bitte bestätige zunächst, dass du kein Roboter bist!';
                return;
            }

            if (this.artist === '' || this.track === '') {
                this.errorMessage = 'Bitte fülle alle Felder aus!';
                return;
            }

            this.errorMessage = '';
            this.$store.dispatch('submitTrackRequest', [this.artist, this.track]);
            this.beforeSubmit = false;
        }
    }
}
</script>