<template>
    <div class="m-3 m-lg-4" ref="container">
        <timeline-section class="d-none" id="timeline" />
        <v-select
            :options="[
                { value: 5, label: '5 Beitraege' },
                { value: 10, label: '10 Beitraege' },
                { value: 20, label: '20 Beitraege' },
                { value: 50, label: '50 Beitraege' },
                { value: 100, label: '100 Beitraege' },
                { value: -1, label: 'Alle Beitraege' } ]"
            :reduce="(option) => option.value"
            :value="this.numEntries"
            :searchable="false"
            :clearable="false"
            @input="this.onNumEntriesChange"
            class="mb-3" />
        <section
            v-for="(itemTimeline, index) in this.$store.getters.getMostRecentEntries"
            :key="index"
            :id="'timeline-item-' + itemTimeline.id"
            class="timeline-item">
            <hr v-if="index > 0" class="mb-0" />
            <hr v-if="index > 0" class="mt-1" />
            <div class="item">
                <h3 class="date-item" v-html="itemTimeline.caption" />
                <h4 class="title-item" v-html="itemTimeline.title" />
                <h5 class="changed-item"><strong>Erstellt:</strong> {{ itemTimeline.changed.toLocaleString('de-DE', dateOptions) }} Uhr</h5>
                <p class="description-item mb-3" v-if="!itemTimeline.introLongShow">
                    <span v-html="itemTimeline.introShort" />
                    <CLink
                        class="ml-2 text-white"
                        @click="itemTimeline.introLongShow = !itemTimeline.introLongShow">
                        <u>Weiterlesen</u>
                    </CLink>
                </p>
                <p class="description-item mb-3" v-if="itemTimeline.introLongShow">
                    <span v-html="itemTimeline.introLong" />
                    <CLink
                        v-if="itemTimeline.introShort.length > 0"
                        class="ml-2 text-white"
                        @click="itemTimeline.introLongShow = !itemTimeline.introLongShow">
                        <u>Text ausblenden</u>
                    </CLink>
                </p>
                <p
                    :id="'description-' + itemTimeline.id"
                    class="description-item"
                    v-html="itemTimeline.description" />
                <CCollapse
                    v-if="itemTimeline.additionalNum > 0"
                    class="mt-3"
                    :show="itemTimeline.additionalShow">
                    <p class="description-item" v-html="itemTimeline.additional" />
                </CCollapse>
                <CCollapse
                    v-if="itemTimeline.tags.length > 0"
                    class="mt-3"
                    :show="itemTimeline.tagsShow">
                    <p class="description-item" v-html="itemTimeline.tags" />
                </CCollapse>
                <CAlert
                    color="secondary"
                    class="px-3 py-2"
                    :show.sync="itemTimeline.hintCounter">
                    Link in die Zwischenablage kopiert.
                    <CProgress
                        :max="3"
                        :value="itemTimeline.hintCounter"
                        height="3px"
                        color="secondary"
                        animate
                    />
                </CAlert>
                <CButtonToolbar class="mt-3" justify>
                    <CButton
                        v-if="itemTimeline.additionalNum > 0"
                        class="mr-2 border-white text-white"
                        color="white"
                        shape="square"
                        size="sm"
                        @click="onAdditionalShowClick(itemTimeline)">
                        {{ (itemTimeline.additionalShow ? 'Nicht mehr anzeigen' : ('Mehr anzeigen... (+' + itemTimeline.additionalNum + ')')) }}
                    </CButton>
                    <CButton
                        v-if="itemTimeline.tags.length > 0"
                        class="mr-2 border-white text-white"
                        color="white"
                        shape="square"
                        size="sm"
                        :title="'Tags ' + (itemTimeline.tagsShow ? 'ausblenden' : 'anzeigen')"
                        @click="itemTimeline.tagsShow = !itemTimeline.tagsShow">
                        <CIcon name="cil-tags" />
                    </CButton>
                    <CButton
                        class="mr-2 border-white text-white"
                        color="white"
                        shape="square"
                        size="sm"
                        title="Eintrag teilen"
                        @click="onShareClick(itemTimeline)">
                        <CIcon name="cil-share" />
                    </CButton>
                    <CButton
                        class="mr-2 border-white text-white"
                        color="white"
                        shape="square"
                        size="sm"
                        title="Informationen mitteilen"
                        @click="$router.push({
                            name: 'report',
                            params: { 
                            selectedEntryID: itemTimeline.id,
                            returnFilterType: $store.getters.getFilterType,
                            returnFilterValue: $store.getters.getFilterValue
                            }
                        });">
                        <CIcon name="cil-bullhorn" />
                    </CButton>
                    <CButton
                        class="border-white text-white"
                        color="white"
                        shape="square"
                        size="sm"
                        title="Löschung beantragen"
                        @click="$router.push({
                            name: 'remove',
                            params: { 
                            selectedEntryID: itemTimeline.id,
                            returnFilterType: $store.getters.getFilterType,
                            returnFilterValue: $store.getters.getFilterValue
                            }
                        });">
                        <CIcon name="cil-bolt" />
                    </CButton>
                </CButtonToolbar>
            </div>
        </section>
    </div>
</template>

<script>
import TimelineSection from './TimelineSection.vue'

export default {
    name: 'VfLRecent',
    components: {
        TimelineSection
    },
    data() {
        return {
            dateOptions: {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            },
            numEntries: 20
        }
    },
    created() {
        this.updateMostRecent();
    },
    mounted() {
        this.$store.commit('setTimelineWidth', [this.$refs.container.clientWidth - 10]);
        this.$store.dispatch('updateFilter', [-1, 0]);
    },
    methods: {
        updateMostRecent() {
            if (!this.$store.getters.isFullyInitialized) {
                setTimeout(this.updateMostRecent, 1000);
                return;
            }

            this.$store.dispatch('updateMostRecent', [this.numEntries]);
        },
        onTransitionEnd(event) {
            event.target.style.overflowY = 'hidden';
            setTimeout(function () { event.target.style.overflowY = 'auto'; });
        },
        onImgClick(id) {
            var el = document.getElementById(id);
            if (el.clickLocked)
                return;

            if (el.clickCount === undefined)
                el.clickCount = 0;
            
            if (el.clickCount === 0)
                setTimeout(() => { el.clickCount = 0; }, 500);
            el.clickCount++;

            el.clickLocked = true;
            setTimeout(() => { el.clickLocked = false; }, 50);

            if (el.zoomToggled) {
                if (el.clickCount < 2)
                    el.clickCount = 0;
                el.style.transform = '';
                setTimeout(() => { el.style.zIndex = 0; }, 400);
                el.zoomToggled = false;
            }

            if (el.clickCount === 2) {
                // double click
                if (this.$isMobile()) {
                    el.style.position = 'relative';
                    el.style.zIndex = 1000;
                    el.style.touchAction = 'manipulation';
                    el.style.transform = 'scale(' + (window.innerHeight - 100) / el.height + ')';
                    el.style.transition = 'transform ease-in-out 0.4s';
                    el.style.transformOrigin = '10px';
                    el.zoomToggled = true;
                    el.addEventListener('webkitTransitionEnd', this.onTransitionEnd);
                }
                else {
                    let win = window.open(el.src, '_blank');
                    win.document.title = el.title;
                    win.focus();
                }
            }
            else if (el.clickCount >= 3) {
                // triple click
                let win = window.open(el.src, '_blank');
                win.document.title = el.title;
                win.focus();
            }
            
            return false;
        },
        onAdditionalShowClick(item) {
            item.additionalShow = !item.additionalShow;
            if (!item.additionalShow) {
                var element = document.getElementById('description-' + item.id);
                window.scrollTo(0, element.offsetTop + element.parentElement.offsetTop);
            }
        },
        onNumEntriesChange(value) {
            this.numEntries = value;
            this.updateMostRecent();
        },
        onShareClick(itemTimeline) {
            let props = this.$router.resolve({ 
                name: 'share',
                params: { selectedEntryIndex: itemTimeline.index }
            });
            let url = location.origin + '/' + props.href;
            let copyClipboard = false;

            if (!navigator.share)
                copyClipboard = true;
            else {
                navigator.share({
                    title: itemTimeline.title,
                    text: 'Schau dir diesen Beitrag auf 50jahrebasketball.de an!',
                    url: url
                })
                .catch(() => copyClipboard = true);
            }

            if (copyClipboard) {
                navigator.clipboard.writeText(url);
                itemTimeline.hintCounter = 3;
            }
        },
        setFilter(type, value) {
            this.$router.push({ name: 'filter', params: { filterType: type, filterValue: value} });
        }
    }
}
</script>

<style lang="scss" scoped>
.timeline-item {
    .item {
        border-left: 0px !important;
        padding: 5px !important;
        position: relative;
    }
    .date-item {
        margin: 0;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 2px;
    }
    .title-item {
        margin: 0;
        padding: 5px 0;
        font-size: 20px;
        font-weight: 500;
    }
    .changed-item {
        margin: 0;
        padding: 0 0 10px;
        font-size: 11px;
        font-style: italic;
        text-decoration: underline;
        font-weight: 200;
    }
    .description-item {
        font-weight: 100;
        margin: 0 10 0 10;
    }
}
</style>