<template>
    <div>
        <timeline-section
            class="mt-0 ml-3 mr-2 pr-3 ml-md-4 mt-md-2 mx-lg-5 mx-xl-5"
            id="timeline"
            ref="timeline"
            :timeline-items="this.$store.getters.getViewEntries"
            :message-when-no-items="'Einen Moment, bitte. Die Daten werden geladen...'"
            colorDots="#53c0ff"
            order="asc"
            uniqueTimeline />
    </div>
</template>

<script>
import TimelineSection from './TimelineSection.vue'

export default {
    name: 'VfLTimeline',
    components: {
        TimelineSection
    },
    created() {
        window.addEventListener("resize", this.onResizeTimeline);
    },
    mounted() {
        this.onResizeTimeline();
        this.$store.dispatch('updateFilter', [this.filterType, this.filterValue]);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResizeTimeline);
    },
    methods: {
        onTransitionEnd(event) {
            event.target.style.overflowY = 'hidden';
            setTimeout(function () { event.target.style.overflowY = 'auto'; });
        },
        onImgClick(id) {
            var el = document.getElementById(id);
            if (el.clickLocked)
                return;

            if (el.clickCount === undefined)
                el.clickCount = 0;
            
            if (el.clickCount === 0)
                setTimeout(() => { el.clickCount = 0; }, 500);
            el.clickCount++;

            el.clickLocked = true;
            setTimeout(() => { el.clickLocked = false; }, 50);

            if (el.zoomToggled) {
                if (el.clickCount < 2)
                    el.clickCount = 0;
                el.style.transform = '';
                setTimeout(() => { el.style.zIndex = 0; }, 400);
                el.zoomToggled = false;
            }

            if (el.clickCount === 2) {
                // double click
                if (this.$isMobile()) {
                    el.style.position = 'relative';
                    el.style.zIndex = 1000;
                    el.style.touchAction = 'manipulation';
                    el.style.transform = 'scale(' + (window.innerHeight - 100) / el.height + ')';
                    el.style.transition = 'transform ease-in-out 0.4s';
                    el.style.transformOrigin = '10px';
                    el.zoomToggled = true;
                    el.addEventListener('webkitTransitionEnd', this.onTransitionEnd);
                }
                else {
                    let win = window.open(el.src, '_blank');
                    win.document.title = el.title;
                    win.focus();
                }
            }
            else if (el.clickCount >= 3) {
                // triple click
                let win = window.open(el.src, '_blank');
                win.document.title = el.title;
                win.focus();
            }
            
            return false;
        },
        onResizeTimeline() {
            this.$store.commit('setTimelineWidth', [this.$refs.timeline.$el.clientWidth - 35]);
        },
        setFilter(type, value) {
            this.$router.push({ name: 'filter', params: { filterType: type, filterValue: value} });
        }
    },
    props: {
        filterType: Number,
        filterValue: Number
    }
}
</script>